@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/1.0.4/leaflet.draw.css');
.leaflet-container {
    margin: 30px auto;
    height: 90%;
    z-index: 0;
    border: 2px solid #333;
    overflow: hidden;
  }
  
iframe {
    display: block;
    width: 100%;
    border: 0;
}
  
.grid {
    display: grid;
    width: 100%;
    grid-template-areas:
        "nav head"
        "nav main";
    grid-template-columns: 300px 1fr;
    grid-template-rows: 100px;
    gap: 0 30px;
    max-width: 1350px;
}
  
header {
    grid-area: head;
}
  
nav {
    grid-area: nav;
}
  
nav {
    width: 100%;
    height: 100vh;
    background: #fff;
    min-height: 100%;
    padding-bottom: 30px;
    border-right: 2px solid #333;
    box-shadow: 0 0 10px rgba(51, 51, 51, 0.2);
}
  
main {
    grid-area: main;
    max-width: 1000%;
    width: 100%;
    // padding-right: 30px;
}
  
@media (max-width: 900px) {
    .grid {
      grid-template-columns: 100%;
      grid-template-areas:
        "head"
        "main";
      gap: 0;
    }
  
    header {
        padding: 20px;
    }
  
    .show-menu {
        position: fixed;
        display: block;
        bottom: 30px;
        right: 0;
        padding: 10px 15px;
        background: #333;
        color: #fff;
        border-radius: 5px 0 0 5px;
        z-index: 10;
    }
  
    nav {
        position: fixed;
        z-index: 9;
        max-width: 320px;
        min-height: 100%;
        width: 100%;
        bottom: 0;
        top: 0;
        transform: translateX(-400px);
        transition: transform 0.5s ease-in-out;
    }
  
    .show-menu-examples nav {
      transform: translateX(0);
    }
  
    nav ul li:hover {
        background: #d6d6d6;
    }
    main {
        padding: 20px;
    }
}
  
.marker-position {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 999;
    padding: 5px;
    font-size: 1rem;
    font-weight: 700;
    background-color: #fff;
}
  
.container {
    display: flex;
    width: 100%;
    max-width: 400px;
    padding: 5px;
    margin: 20px auto;
}
  
.marker-click {
    text-decoration: none;
    color: red;
}
  
.space-between {
    justify-content: space-between;
}
  
/* legend  */
.legend {
    background: #fff;
    padding: 8px;
    border-radius: 5px;
}
  
.legend .row {
    display: flex;
    line-height: 30px;
}
  
.legend i {
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 5px;
}
  
.description {
    width: 250px;
    font-size: 16px;
    color: #333;
    padding: 10px 14px;
    background-color: hsla(0, 0%, 100%, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border: 1px solid grey;
}